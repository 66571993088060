export function formatNumberToString(n: number) {
  let formatted = '';
  switch (true) {
    case n >= 1e12:
      formatted = +(n / 1e12).toFixed(1) + 'T';
      break;
    case n >= 1e9:
      formatted = +(n / 1e9).toFixed(1) + 'B';
      break;
    case n >= 1e6:
      formatted = +(n / 1e6).toFixed(1) + 'M';
      break;
    case n >= 1e3:
      formatted = +(n / 1e3).toFixed(1) + 'K';
      break;
    default:
      formatted = n.toString();
      break;
  }

  return formatted;
}
