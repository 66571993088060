/*
      ---< Node Utilities >---

 - PolynetNodeObject: nodes interface
 - formatNumberToString: formats big number to string
 - getNodeNavigationInfo: builds collapsed and root nodes info to show
 - graphDepth: calc graph depth x, y, z to see its size in terms of coordinates
 - distanceBetweenTwoPoints: calc distance between two points in a 3D space
 - distanceFromNearPlane: calculates despite the fact a node is not close to camera position if this might be parallel to camera near area to show collapsed info
 - collapsedNodeLabelVisible: main function which sets if collapsed node info should be shown or hidden

 */
import * as THREE from 'three';

export interface PolynetNodeObject {
  id?: string | number;
  x?: number;
  y?: number;
  z?: number;
  vx?: number;
  vy?: number;
  vz?: number;
  fx?: number;
  fy?: number;
  fz?: number;
  children: number;
  collapsed: boolean;

  size: number;
  level: number;

  type: string;
}

export function getNodeNavigationInfo(navigationInfo: string, className: string, icon = '') {
  const nodeEl = document.createElement('div');
  const nodeElIcon = document.createElement('div');
  const nodeElValue = document.createElement('div');

  nodeElValue.textContent = `${navigationInfo}`;

  if (icon !== '') {
    nodeElIcon.innerHTML = icon;
    nodeEl.appendChild(nodeElIcon);
  }

  nodeEl.appendChild(nodeElValue);
  nodeEl.className = className;

  return nodeEl;
}

export function graphDepth(topologyGraphRef: any) {
  const vector = new THREE.Vector3();
  const graphDirection = topologyGraphRef.camera().getWorldDirection(vector).negate();
  const graphDimension = topologyGraphRef.getGraphBbox();
  let graphDepth = 0;

  if (graphDimension !== undefined && graphDimension !== null) {
    if (Math.abs(graphDirection.x) > 0.89 && graphDimension.x !== undefined) {
      graphDepth = graphDimension.x[0] - graphDimension.x[1];
    } else if (Math.abs(graphDirection.y) > 0.89 && graphDimension.y !== undefined) {
      graphDepth = graphDimension.y[0] - graphDimension.y[1];
    } else if (graphDimension.z !== undefined) {
      graphDepth = graphDimension.z[0] - graphDimension.z[1];
    }
  }

  return Math.abs(graphDepth) > 500 ? Math.abs(graphDepth) : 500;
}

export function distanceBetweenTwoPoints(originPosition: any, endPosition: any) {
  const dx = originPosition.x - endPosition.x;
  const dy = originPosition.y - endPosition.y;
  const dz = originPosition.z - endPosition.z;

  return Math.sqrt(dx * dx + dy * dy + dz * dz);
}

export function distanceFromNearPlane(cameraPosition: any, nodePosition: any, topologyGraphRef: any) {
  const nearPlane = { ...nodePosition };
  const nodeVector = { ...nodePosition };
  let direction = 'z';
  if (topologyGraphRef !== null) {
    const vector = new THREE.Vector3();
    const graphDirection = topologyGraphRef.camera().getWorldDirection(vector).negate();
    if (Math.abs(graphDirection.x) > 0.89) {
      direction = 'x';
    } else if (Math.abs(graphDirection.y) > 0.89) {
      direction = 'y';
    }
  }

  switch (direction) {
    case 'x':
      nearPlane.x = cameraPosition.x;
      break;
    case 'y':
      nearPlane.y = cameraPosition.y;
      break;
    case 'z':
      nearPlane.z = cameraPosition.z;
      break;
  }

  return distanceBetweenTwoPoints(nearPlane, nodeVector);
}

let scalingValue = (distance: any, depth: number) => {
  const factor: number = 0.9 - distance / depth;
  return factor <= 0 ? 0 : factor;
};
export function collapsedNodeLabelVisible(graphData: any, topologyGraphRef: any) {
  if (graphData !== null) {
    const depth = graphDepth(topologyGraphRef);

    graphData.nodes
      .filter((node: any) => node.collapsed === true && node.children > 0)
      .map((node: any) => {
        const nodeLabelEl = document.getElementById(`node-label-${node.id}`);
        if (nodeLabelEl && node?.__threeObj !== undefined) {
          const cameraPosition = topologyGraphRef.camera().position.clone();
          const nodePosition = node.__threeObj.position.clone();
          const distance = distanceBetweenTwoPoints(cameraPosition, nodePosition);
          const distanceNearPlane = distanceFromNearPlane(cameraPosition, nodePosition, topologyGraphRef);

          if (!nodeLabelEl.classList.contains('node-label-permanent')) {
            if (distance <= depth || distanceNearPlane <= depth) {
              nodeLabelEl.classList.add('visible');
            } else {
              nodeLabelEl.classList.remove('visible');
            }
          }
          // Label scaling with distance
          //@ts-ignore
          nodeLabelEl.style.opacity = 0.3 + scalingValue(distance, depth);
          //@ts-ignore
          nodeLabelEl.style.fontSize = `${0.8 + scalingValue(distance, depth)}rem`;
        }
      });
  }
}
