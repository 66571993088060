import React, { useCallback, useEffect, useRef, useState } from 'react';
import { PanelProps } from '@grafana/data';
import { SimpleOptions, InfoBoxItem } from 'types';

//import * as d3 from 'd3-force-3d';
import { css, cx } from '@emotion/css';
import { useStyles2 } from '@grafana/ui';
import ForceGraph3d from 'react-force-graph-3d';
import { CSS2DRenderer, CSS2DObject } from 'three/examples/jsm/renderers/CSS2DRenderer';
import * as THREE from 'three';
import chroma from 'chroma-js';

//====== custom plugin
import useGraphData from 'hooks/useGraphData';

import { MessageModal, MessageModalControls } from './MessageModal';
import { InfoBox } from './InfoBox';
import { PopoverMenu } from './PopoverMenu';
import { Loading } from './Loading';
import { getLayout } from 'interface/gui-extra';
import { globalFlags } from 'globalflags';
import { NodeColorSelector, nodeColorSelectorFactory, PolynetNodeObject3D } from 'nodeColorStrategies';
import { formatNumberToString } from ' commonUtils';
import { getNodeNavigationInfo, collapsedNodeLabelVisible } from 'nodeutils';
import { library, icon } from '@fortawesome/fontawesome-svg-core';
import { faArrowLeft, faWarning } from '@fortawesome/free-solid-svg-icons';
import 'style.css';
//====

interface Props extends PanelProps<SimpleOptions> {}

const NO_DATA_WITHIN_30_DAYS = 'There is no data to display for the selected end of time interval.';
const NO_DATA_OUT_OF_RANGE = 'Please select an interval ending within the last 30 days for this chart.';
const NODES_THRESHOLD_CONFIRM =
  "Incrementing the suggested nodes threshold might affect the panel's performance. Do you want to continue?";
const LOADING_NODES_EXPANDED = 'Please wait while nodes are loaded';

const controls = {
  Metric: 'BufferHealth',
  Layout: 'Top-Down',
  GhostNode: false,
}; // Default values

let highlightNodeId: string | undefined;

//let function_lock = false;
//let collisionTimer: NodeJS.Timeout;

export const SimplePanel2: React.FC<Props> = ({ width, height, data }) => {
  const topologyRef = useRef<any>();
  const menuRef = useRef<any>();
  const styles = useStyles2(getStyles);
  const returnIcon = icon({ prefix: 'fas', iconName: 'arrow-left' });
  const nodeColorSelector = new NodeColorSelector(nodeColorSelectorFactory(controls.Metric));
  const extraRenderers: any[] = [new CSS2DRenderer()];
  library.add(faArrowLeft, faWarning);
  const [expandedGroup, setExpandedGroup] = useState('');
  const [showHighlightedLinks, setShowHighlightedLinks] = useState(false);
  const [noDataFlag, setNoDataFlag] = useState(false);
  const [noDataMessage, setNoDataMessage] = useState('');
  const [infoBoxData, setInfoBoxData] = useState<InfoBoxItem[]>([]);
  const [layoutState, setLayoutState] = useState({ layout: 'Top-Down' });
  const [metricState, setMetricState] = useState({ metric: 'BufferHealth' });
  const [onlyP2PState, setOnlyP2PState] = useState(true);
  const [nodesThresholdState, setNodesThresholdState] = useState({ nodesThreshold: globalFlags.maxNumberTreeNodes });
  const [nodesThresholdConfirmationPopup, setNodesThresholdConfirmationPopup] = useState(false);
  const [nodesThresholdConfirmationFlag, setNodesThresholdConfirmationFlag] = useState(false);
  const [maxGlowSizeState, setMaxGlowSizeState] = useState(10);
  const prevThresholdRef = useRef<number>(globalFlags.maxNumberTreeNodes);

  const [warmupTicks, setWarmupTicks] = useState(0);
  const [cooldownTicks, setCooldownTicks] = useState(Infinity);
  const [cooldownTime, setCooldownTime] = useState(globalFlags.cooldownTimeMs);
  const [showLoading, setShowLoading] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState('');

  const {
    advanceTier,
    grafanaData,
    groups,
    graphData,
    graphDateInfo,
    monthTimeRange,
    expandedNodeZoom,
    resetExpandedNodeZoom,
    highlightedLinks,
    highlightedGroups,
  } = useGraphData(data, {
    expandGroup: expandedGroup,
    nodesThreshold: nodesThresholdState.nodesThreshold,
    onlyP2PNodes: onlyP2PState,
  });

  const nodeColorCallback = (node: any) => {
    nodeColorSelector.setColorStrategy(nodeColorSelectorFactory(metricState.metric));
    return nodeColorSelector.colorFunction(node as PolynetNodeObject3D, highlightNodeId);
  };

  const nodeDragEndCallback = (node: any) => {
    const myNode = node as PolynetNodeObject3D;
    myNode.fx = myNode.x;
    myNode.fy = myNode.y;
    myNode.fz = myNode.z;
  };

  const handleNodeClick = (node: any) => {
    const myNode = node as PolynetNodeObject3D;
    if (myNode.type === 'subgroup' && myNode?.children > 0 && node.collapsed) {
      setExpandingParameters(false);
      setTimeout(() => {
        setExpandedGroup(myNode.id?.toString() ?? '');
      }, 500);
    }
  };

  const onEngineStopCallback = () => {
    if (globalFlags.firstTimeZoom) {
      //collisionDetection();
      topologyRef.current.zoomToFit(globalFlags.zoomToFitDelayMs);
      globalFlags.firstTimeZoom = false;
      const topologyRefCopy = topologyRef.current;
      collapsedNodeLabelVisible(graphData, topologyRefCopy);
    }
    if (expandedNodeZoom !== '' && graphData?.nodes !== undefined) {
      handleZoomToNodesExpanded();
      setShowHighlightedLinks(true);
    }
  };

  const handleZoomToNodesExpanded = useCallback(() => {
    const nodeInfo = graphData.nodes.find((node: any) => node.id === expandedNodeZoom);

    if (nodeInfo) {
      const distance = grafanaData.idValues.length > 999 ? 1000 : 500;
      const distRatio = 1 + distance / Math.hypot(nodeInfo.x, nodeInfo.y, nodeInfo.z);
      topologyRef.current.cameraPosition(
        { x: nodeInfo.x * distRatio, y: nodeInfo.y * distRatio, z: nodeInfo.z * distRatio },
        nodeInfo,
        3000
      );
    }
    resetExpandedNodeZoom();
    setShowLoading(false);
    setLoadingMessage('');
    setWarmupTicks(0);
    setCooldownTicks(Infinity);
  }, [expandedNodeZoom, graphData?.nodes, grafanaData.idValues, resetExpandedNodeZoom]);

  const finalMeshCallback = (node: any, nodeLabel: any) => {
    // Smaller solid sphere mesh.
    const geometrySolid = new THREE.SphereGeometry(Math.cbrt(nodesThresholdState.nodesThreshold ?? 4) / 2, 8, 4);
    const materialSolid = new THREE.MeshLambertMaterial({
      color: nodeColorCallback(node),
      transparent: false,
      opacity: 1.0,
    });
    const mesh = new THREE.Mesh(geometrySolid, materialSolid);
    mesh.name = 'node';

    // Place the smaller solid sphere inside the large glow sphere.

    const finalMesh = new THREE.Group();

    if ((node.collapsed || node.type === 'root') && node.id !== 'CDN') {
      // Glow sphere mesh.
      let glowSize =
        Math.cbrt(nodesThresholdState.nodesThreshold ?? 7) +
        (node.children > 10 ? Math.sqrt(node.children) : node.children);
      glowSize = glowSize > 18 ? 18 : glowSize;
      setMaxGlowSizeState(glowSize >= maxGlowSizeState ? glowSize : maxGlowSizeState);
      const geometry = new THREE.SphereGeometry(glowSize, 8, 4);
      const material = new THREE.MeshLambertMaterial({
        color: '#ffffff',
        opacity: 0.5,
        alphaHash: true,
        alphaTest: 0.5,
      });
      const glowEffect = new THREE.Mesh(geometry, material);
      glowEffect.name = 'glow';

      finalMesh.add(glowEffect);
      finalMesh.add(nodeLabel);
    }
    finalMesh.add(mesh);
    return finalMesh;
  };
  const cleanLabels = () => {
    let elements = document.querySelectorAll('.node-label,.node-label-root,.node-label-permanent');
    for (let nodeLabel of elements) {
      nodeLabel.remove();
    }
  };

  const nodeElementsCallback = (node: any) => {
    // CLEAN labels
    cleanLabels();

    let nodeLabel = new CSS2DObject(document.createElement('div'));
    let nodeText = '';

    if (node.type === 'root' && node.id !== globalFlags.poiId) {
      nodeLabel = new CSS2DObject(getNodeNavigationInfo(nodeText, 'node-label-root', returnIcon.html[0]));
      nodeLabel.position.set(-7, 0, 0);
    } else if (node.collapsed !== undefined && node.children !== undefined) {
      if (node.collapsed === true && node.children > 0) {
        nodeText = `+${formatNumberToString(node.children)}`;
        const nodeLabelEl = getNodeNavigationInfo(
          nodeText,
          highlightedGroups.includes(node.id) ? 'node-label-permanent' : 'node-label',
          ''
        );
        nodeLabelEl.id = `node-${node.id}`;
        nodeLabelEl.setAttribute('id', `node-label-${node.id}`);
        nodeLabel = new CSS2DObject(nodeLabelEl);
        nodeLabel.position.set(0, -9, 0);
      }
    }

    return finalMeshCallback(node, nodeLabel);
  };

  const handleMetricChange = (metricValue: any) => {
    setMetricState({ metric: metricValue.name });
    controls.Metric = metricState.metric;
  };
  const handleLayoutChange = (layoutValue: any) => {
    setLayoutState({ layout: layoutValue.name });
    controls.Layout = layoutState.layout;
  };

  const handleOnlyP2PSwitch = (filterValue: boolean) => {
    setExpandingParameters(true);
    setOnlyP2PState(filterValue);
  };

  const handleNodesThresholdConfirm = () => {
    setNodesThresholdConfirmationPopup(false);
    setCooldownTime(globalFlags.cooldownTimeMs);
    globalFlags.firstTimeZoom = true;
    topologyRef.current.resumeAnimation();
    //collisionDetection();
  };

  const handleNodesThresholdCancel = () => {
    setNodesThresholdConfirmationFlag(false);
    setNodesThresholdConfirmationPopup(false);
    setNodesThresholdState({ nodesThreshold: prevThresholdRef.current });
    globalFlags.firstTimeZoom = true;
    topologyRef.current.resumeAnimation();
  };

  const handleThresholdSlider = (thresholdValue: number) => {
    if (!nodesThresholdConfirmationFlag && thresholdValue > globalFlags.maxNumberTreeNodes) {
      topologyRef.current.pauseAnimation();
      thresholdValue = globalFlags.maxNumberTreeNodes;
      setNodesThresholdConfirmationFlag(true);
      setNodesThresholdConfirmationPopup(true);
      setExpandedGroup('');
      setCooldownTime(globalFlags.cooldownTimeMs);
      globalFlags.firstTimeZoom = true;
    } else {
      setNodesThresholdConfirmationPopup(false);
      setExpandedGroup('');
      setCooldownTime(globalFlags.cooldownTimeMs);
      globalFlags.firstTimeZoom = true;
      onEngineStopCallback();
    }
    return nodesThresholdState.nodesThreshold;
  };

  const setExpandingParameters = useCallback((reset = false) => {
    if (reset === true) {
      //reset expanded group
      setCooldownTime(globalFlags.cooldownTimeMs);
      setWarmupTicks(0);
      setCooldownTicks(Infinity);
      setExpandedGroup('');
      setShowHighlightedLinks(false);
      setShowLoading(false);
      globalFlags.firstTimeZoom = true;
    } else {
      setCooldownTime(0);
      setWarmupTicks(100);
      setCooldownTicks(0);
      setShowLoading(true);
      setLoadingMessage(LOADING_NODES_EXPANDED);
      setShowHighlightedLinks(false);
    }
  }, []);

  useEffect(() => {
    //reset expanded group
    setExpandingParameters(true);
  }, [data?.series, setExpandingParameters]);

  useEffect(() => {
    const topologyRefCopy = topologyRef.current;
    if (groups.length) {
      console.log('ADD EVENT LISTENER', groups);
      topologyRefCopy.controls().addEventListener('end', () => collapsedNodeLabelVisible(graphData, topologyRefCopy));
    }

    return () => {
      console.log('REMOVE EVENT LISTENER');
      topologyRefCopy
        .controls()
        .removeEventListener('end', () => collapsedNodeLabelVisible(graphData, topologyRefCopy));
    };
  }, [graphData, groups]);

  useEffect(() => {
    if (graphData?.nodes?.length <= 1) {
      setNoDataFlag(true);
      if (monthTimeRange === true) {
        setNoDataMessage(NO_DATA_WITHIN_30_DAYS);
      } else {
        setNoDataMessage(NO_DATA_OUT_OF_RANGE);
      }
      setInfoBoxData([]);
    } else {
      setNoDataFlag(false);
      setNoDataMessage('');
      if (graphDateInfo !== '') {
        setInfoBoxData([
          {
            label: 'Date:',
            value: graphDateInfo,
            showLabel: false,
          },
          {
            label: '# Nodes:',
            value: grafanaData.idValues.length.toString(),
            showLabel: true,
          },
        ]);
      }
    }
  }, [graphData, grafanaData, monthTimeRange, graphDateInfo]);

  useEffect(() => {
    console.log('extra', setShowHighlightedLinks);
  }, []);

  useEffect(() => {
    console.log('check Hook data');
    console.log('graphData: ', graphData);
    console.log(
      advanceTier,
      grafanaData,
      graphDateInfo,
      monthTimeRange,
      expandedNodeZoom,
      resetExpandedNodeZoom,
      highlightedLinks,
      highlightedGroups
    );
  }, [
    advanceTier,
    expandedNodeZoom,
    grafanaData,
    graphData,
    graphDateInfo,
    highlightedGroups,
    highlightedLinks,
    monthTimeRange,
    resetExpandedNodeZoom,
  ]);

  return (
    <div
      className={cx(
        styles.wrapper,
        css`
          width: ${width}px;
          height: ${height}px;
        `
      )}
    >
      <div id="3d-graph">
        <ForceGraph3d
          ref={topologyRef}
          extraRenderers={extraRenderers}
          graphData={graphData ?? { nodes: [], links: [] }}
          nodeVal={(node: any) => (node as PolynetNodeObject3D).size}
          linkWidth={(link) => {
            const targetId = link?.target?.id !== undefined ? link?.target?.id : link?.target;
            const targetInfo = graphData !== null ? graphData.nodes.find((node: any) => node.id === targetId) : null;

            if (targetInfo) {
              if (targetInfo?.children > 0 && targetInfo?.collapsed === true) {
                return globalFlags.groupLinkWidth;
              }
            }
            return globalFlags.linkWidth;
          }}
          controlType="orbit"
          height={height}
          width={width}
          nodeId="id"
          nodeLabel="tooltip"
          nodeRelSize={Math.cbrt(nodesThresholdState.nodesThreshold ?? 4) * 0.75}
          //@ts-ignore
          dagMode={getLayout(layoutState['layout'])}
          d3VelocityDecay={globalFlags.velocityDecay}
          cooldownTime={cooldownTime}
          warmupTicks={warmupTicks}
          cooldownTicks={cooldownTicks}
          onEngineStop={onEngineStopCallback}
          showNavInfo={false}
          linkColor={() => chroma(globalFlags.linkColor).darken(0).hex()}
          linkDirectionalParticles={4}
          linkDirectionalParticleWidth={(link) => (highlightedLinks.has(link) && showHighlightedLinks ? 10 : 0)}
          linkDirectionalParticleColor={() => chroma(globalFlags.nodeHighlightColor).darken(1).hex()}
          nodeColor={nodeColorCallback}
          nodeOpacity={1}
          linkOpacity={0.2}
          onNodeClick={handleNodeClick}
          nodeResolution={4}
          onNodeDragEnd={nodeDragEndCallback}
          nodeThreeObject={nodeElementsCallback}
          nodeThreeObjectExtend={true}
          enableNavigationControls={true}
        />
      </div>
      {infoBoxData.length && (
        <div className="controls-panel ">
          <PopoverMenu
            menuRef={menuRef}
            controls={{
              Metric: metricState.metric,
              Layout: layoutState.layout,
            }}
            isAdvanceTier={advanceTier}
            graphTotal={grafanaData.idValues.length}
            nodeLimit={globalFlags.maxNumberTreeNodes}
            lockSlide={nodesThresholdConfirmationPopup}
            thresholdValue={nodesThresholdState.nodesThreshold}
            setThresholdValue={setNodesThresholdState}
            prevThresholdValueRef={prevThresholdRef}
            layoutAction={handleLayoutChange}
            metricAction={handleMetricChange}
            onlyP2PValue={onlyP2PState}
            onlyP2PAction={handleOnlyP2PSwitch}
            rangeMin={10}
            thresholdAction={handleThresholdSlider}
          />
        </div>
      )}

      <InfoBox items={infoBoxData} containerWidth={width} />
      <MessageModal show={noDataFlag} message={noDataMessage} />
      <MessageModal show={nodesThresholdConfirmationPopup} message={NODES_THRESHOLD_CONFIRM}>
        <MessageModalControls
          confirm={true}
          cancel={true}
          confirmAction={handleNodesThresholdConfirm}
          cancelAction={handleNodesThresholdCancel}
        />
      </MessageModal>
      <Loading show={showLoading} message={loadingMessage} />
    </div>
  );
};

const getStyles = () => ({
  wrapper: css`
    font-family: Open Sans;
    position: relative;
  `,
  svg: css`
    position: absolute;
    top: 0;
    left: 0;
  `,
  textBox: css`
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 10px;
  `,
});
